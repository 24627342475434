import React from 'react';
import { useFormContext } from 'react-hook-form';
import useInput from '../../hooks/use-input';
import { PROGRAMA_FINANCIAR } from '../../constants/form/validations';
import FormInput from '../Input/FormInput';
import * as CONSTANTS from '../../constants/form/request-form';
import { useState } from 'react';
import Tuple from '../FinancialStatement/Tuple';
import { formatCurrency } from '../../utils/format';
import { ReactComponent as InfoIco } from '../../assets/images/info.svg';
import { INFO_TITLE } from '../../constants/shared/infoTitle';

const SimuladorFinanciacionApi = ({ infoOrdenMatricula, fetchedConsultCreditRenovation = null }) => {
  const [infoOrdenMatricul] = useState(infoOrdenMatricula);
  const {
    register,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const { InputProps } = useInput({
    errors,
    getValues,
    register,
    control,
    validations: PROGRAMA_FINANCIAR,
  });
  const watchDepartamentos = watch('incluir_seguro');
  const watchLinea = fetchedConsultCreditRenovation ? String(fetchedConsultCreditRenovation.linea.id) : watch('linea');
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className=" grid text-right grid-cols-6">
        <p className="font-bold text-center text-custom-green col-span-6 m-2">
          Calculadora de financiación con Educación Estrella®
        </p>
        <div className=" m-1 col-span-3">
          <b>
            <span className="dark:text-custom-green col-span-2">
              ¿Incluir el seguro de la
            </span>
          </b>
          <br />
          <b>
            <span className="dark:text-custom-green col-span-2">
              Universidad en la financiación?
            </span>
          </b>
        </div>
        <div className=" m-1 col-span-2">
          <FormInput
            {...InputProps('incluir_seguro')}
            type="select"
            options={CONSTANTS.BINARIO}
          />
        </div>
        <div title={INFO_TITLE.INCLUIR_SEGURO} className=" h-5 w-5  mt-5">
          <InfoIco className="h-auto w-5 flex-shrink-0 fill-current" />
        </div>
        <div className=" m-1 col-span-3">
          <b>
            <span className="dark:text-custom-green col-span-2">
              Valor base de la financiación:
            </span>
          </b>
        </div>
        <div className=" m-1 col-span-2">
          <Tuple
            value={
              watchLinea?.toString() === '2'
                ? formatCurrency(
                    watchDepartamentos === 'true'
                      ? Math.round(
                          parseInt(infoOrdenMatricul.matricula) * 0.65
                        ) + parseInt(infoOrdenMatricul.seguro)
                      : Math.round(infoOrdenMatricul.matricula * 0.65)
                  )
                : formatCurrency(
                    watchDepartamentos === 'true'
                      ? Math.round(parseInt(infoOrdenMatricul.valorPago))
                      : infoOrdenMatricul.matricula
                  )
            }
          />
        </div>
        <div title={INFO_TITLE.VALOR_BASE} className=" h-5 w-5 content-center">
          <InfoIco className="h-auto w-5 flex-shrink-0 fill-current" />
        </div>
        {watchLinea?.toString() === '2' && (
          <>
            <div className=" m-1 col-span-3">
              <b>
                <span className="dark:text-custom-green">
                  Este es el 35% condonable:
                </span>
              </b>
            </div>
            <div className=" m-1 col-span-2">
              <Tuple
                value={formatCurrency(
                  Math.round(infoOrdenMatricul.matricula * 0.35)
                )}
              />
            </div>
            <div
              title={INFO_TITLE.CONDONABLE}
              className=" h-5 w-5 content-center"
            >
              <InfoIco className="h-auto w-5 flex-shrink-0 fill-current" />
            </div>
            <div className=" m-1 col-span-3">
              <b>
                <span className="dark:text-custom-green text-[#3F2AAD]">
                  Este el 65% financiable:
                </span>
              </b>
            </div>
            <div className=" m-1 col-span-2">
              <Tuple
                value={formatCurrency(
                  watchDepartamentos === 'true'
                    ? Math.round(parseInt(infoOrdenMatricul.matricula) * 0.65) +
                        parseInt(infoOrdenMatricul.seguro)
                    : Math.round(infoOrdenMatricul.matricula * 0.65)
                )}
              />
            </div>
            <div
              title={INFO_TITLE.FINANCIABLE}
              className=" h-5 w-5 content-center"
            >
              <InfoIco className="h-auto w-5 flex-shrink-0 fill-current" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SimuladorFinanciacionApi;
