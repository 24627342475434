import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Item from './Item';

const DynamicForm = ({
  formData,
  loanId,
  onSubmit: onParentSubmit,
  submit: parentSubmit,
}) => {
  const methods = useFormContext();
  if (!methods) throw new Error('DynamicForm must be used within FormProvider');

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const [attachments, setAttachments] = useState({});
  const setAttachment = (name, value) =>
    setAttachments((current) => ({ ...current, [name]: value }));

  const colSpans = {
    1: 'xl:col-span-1 lg:col-span-2 col-span-1',
    2: 'xl:col-span-2 lg:col-span-2 col-span-1',
    3: 'xl:col-span-3 lg:col-span-4 col-span-1',
    4: 'xl:col-span-4 lg:col-span-4 col-span-1',
  };

  const onSubmit = async (values) => {
    const questions = [];
    formData.template.forEach((section, sectionIndex) =>
      section.items.forEach((item, itemIndex) => {
        const [i, j] = [sectionIndex + 1, itemIndex + 1];
        questions.push({
          number: i + '.' + j,
          question: item.title,
          type: item.type,
          answer: values[i][j],
        });
      })
    );
    await onParentSubmit({
      formId: formData.formId,
      universityId: formData.universityId,
      loanId,
      questions,
      attachments: Object.keys(attachments).map((key) => ({
        name: key,
        key: attachments[key],
      })),
    });
  };

  useEffect(() => {
    if (parentSubmit) handleSubmit(onSubmit)();
  }, [parentSubmit]);

  return (
    <form className="my-4 space-y-4">
      {formData.template.map((section, sectionIndex) => (
        <section key={sectionIndex}>
          <h2 className="pb-4 font-bold text-custom-green">{section.title}</h2>
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-4">
            {section.items.map(
              ({ colSpan = 1, title, subTitle, ...field }, fieldIndex) => {
                const error = errors?.[sectionIndex + 1]?.[fieldIndex + 1];
                return (
                  <div
                    key={fieldIndex}
                    className={`flex flex-col ${colSpans[colSpan]}`}
                  >
                    <div className="mb-4 font-bold">
                      {title && (
                        <h3>
                          {fieldIndex + 1}. {title}{' '}
                          <span className="font-normal">{subTitle}</span>
                        </h3>
                      )}
                      {error && (
                        <p className="mt-0.5 block text-xs text-error">
                          *{error.message}
                        </p>
                      )}
                    </div>

                    <Item
                      index={`${sectionIndex + 1}.${fieldIndex + 1}`}
                      {...field}
                      control={control}
                      loanId={loanId}
                      prefix={'forms/' + formData.formId}
                      setAttachment={setAttachment}
                    />
                  </div>
                );
              }
            )}
          </div>
        </section>
      ))}
    </form>
  );
};

export default DynamicForm;
