export const ALERTDIALOG = {
  P1PENDINGORDER:
    '¡Ingresa Aquí! Te falta completar tu solicitud: Firmas de autorización',
  P1PENDINGORDERLP:
    '¡Ingresa Aquí! Te falta completar tu solicitud para Línea Pro',
  P1PENDINGORDERMatricula:
    '¡Ingresa Aquí! Cuando tengas tu orden de matrícula vigente, para completar el paso.',
  P1PENDINGORDERREVIEW:
    'Estamos realizando la validación de tu orden de matrícula. Continúa con el paso 2.',
  P1PENDINGORDERNOVELTY:
    '¡Ingresa Aquí! Tu Orden de Matrícula tiene una novedad.',
  P1PENDINGORDERCOMPLETE: 'Paso Completo.',
  P2PENDINIDENTITY:
    '¡Ingresa Aquí! Te falta completar la validación de identidad para que iniciemos el estudio de tu solicitud',
  P2FAILEDIDENTITYCHECK: '¡Ingresa Aquí! Tienes una novedad con la verificación de identidad',
  P2PENDINGORDERCOMPLETE: '¡Segundo paso completo!',
  P3PENDINSIGNATURE: '¡Ingresa Aquí! Te falta completar firmas en los documentos de legalización',
  P3FAILEDSIGNATURE: '¡Ingresa Aquí! Tienes una novedad con las firmas de los documentos de legalización',
  P3PENDINGORDERCOMPLETE: '¡Tercer paso completo!',
  P1PENDINGORDERUNDERREVIEW: 'Incompleta',
  // P1ARREARS: 'SIN INICIAR',
  P1PENDINGORDERUNDERREVIEWSUBTEXT:
    'Completa lo que tienes pendiente para que podamos iniciar tu estudio',
  P1ARREARSSUBTEXT: 
    'Tienes una financiación con nosotros EN MORA, para iniciar tu solicitud debes estar 100% al día.',
  P1PENDINGORDERFACTORY: 'En Estudio',
  P1PENDINGORDERFACTORYSUBTEXT:
    'Estamos realizando el estudio de tu solicitud, en un promedio de 48 horas te daremos respuesta',
  P1PENDINGORDERPREAPPROVED: 'Pre Aprobada',
  P1PENDINGORDERPREAPPROVEDSUBTEXT:
    '¡Felicitaciones!, tu solicitud fue preaprobada, estamos alistando tus documentos de legalización, en las próximas horas te habilitaremos el último paso',
  P1PENDINGORDERLEGALIZATION: 'En Documentos y Firmas',
  P1PENDINGORDERLEGALIZATIONSUBTEXT:
    'Es momento de firmar los documentos de legalización, ingresa al paso 3, y revisa que tú y tus codeudores lo hagan exitosamente.',
  P1PENDINGORDERLEGALIZED: 'Notificado a la Universidad',
  P1PENDINGORDERLEGALIZEDSUBTEXT:
    '¡Felicitaciones Estrella!, Completaste el 100% de tu financiación y ya hemos notificado a la Universidad.',
  P1PENDINGORDERCOMMITTEE: 'Cambio de Codeudor',
  P1PENDINGORDERCOMMITTEESUBTEXT:
    'Tu solicitud de financiación no fue aprobada en las condiciones que te presentaste. Sin embargo, existe la posibilidad para ti de generar una nueva solicitud con un cambio de codeudores. ',
  P1PENDINGORDERCOMMITTEELINKCLICK:
    'https://info.educacionestrella.com/es-co/cambiocodeudor',
  P1PENDINGORDERREJECTED: 'No Aprobada',
  P1PENDINGORDERREJECTEDSUBTEXT:
    'Lamentamos comunicarte que tu solicitud no pasó el estudio, durante este semestre no es posible presentar una nueva aplicación',
  P1PENDINGORDERCANCELED: 'Desistida',
  P1PENDINGORDERCANCELEDSUBTEXT:
    'Tu solicitud fue marcada como desistida. Posiblemente tú lo solicitaste, o se registró abandono de tu parte en el proceso pendiente',
  P1PENDINGORDERRETURNED: 'Devuelta',
  P1PENDINGORDERRETURNEDSUBTEXT:
    'Estamos realizando el estudio de tu solicitud, pero presenta una novedad. ',
  P1PENDINGORDERRETURNEDLINKCLICK:
    'https://info.educacionestrella.com/es-co/solicituddevuelta',
  LINECHANGE: 'Cambio de Línea',
  LINECHANGESUBTEXT:
    'Tu solicitud no cumple los parámetros para acceder a la línea de financiación con beneficio Tasa 0% de interés de tu Universidad como #MiembroDeEducaciónEstrella, pero no te preocupes, tenemos una alternativa preaprobada para ti. ',
  LINECHANGELINKCLICK: 'https://info.educacionestrella.com/es-co/cambiodelinea',
};
