import React, { useEffect, useRef, useMemo, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { ACT_ECO_ESTUDIANTE } from '../../../constants/form/validations';
import FormInput from '../../../components/Input/FormInput';
import useInput from '../../../hooks/use-input';

const ActEcoEstudiante = ({ formConstants, numRef }) => {
  const isMounted = useRef(false);
  const {
    register,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useFormContext();

  const watchTrabaja = watch('trabaja');
  const watchTipoDocumento = watch('tipo_documento');
  let seeActivity = false;

  const watchRef = [...Array(numRef)].map((e, i) =>
    watch([
      `nombre_ref${i}`,
      `tipo_ref${i}`,
      `celular_ref${i}`,
      `correo_ref${i}`,
    ])
  );
  const watchTipoRef = [...Array(numRef)].map((e, i) => +watch(`tipo_ref${i}`));
  const validations = useMemo(
    () => ACT_ECO_ESTUDIANTE(numRef, watchRef, watchTipoRef),
    [numRef, watchRef, watchTipoRef]
  );

  const { InputProps } = useInput({
    errors,
    getValues,
    register,
    control,
    validations,
  });

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const renderReferencia = (i) => (
    <Fragment key={i}>
      <FormInput
        {...InputProps(`tipo_ref${i}`)}
        placeholder="Tipo de referencia"
        type="select"
        options={formConstants.TIPO_REFERENCIA?.filter(
          (element) =>
            element.value === watchTipoRef[i] ||
            !watchTipoRef.includes(element.value)
        )}
        className="col-span-2 lg:col-span-3"
      />
      <FormInput
        {...InputProps(`nombre_ref${i}`)}
        placeholder="Nombre y apellido"
        type="text"
        className="col-span-2 lg:col-span-3"
      />
      <FormInput
        {...InputProps(`celular_ref${i}`)}
        placeholder="Celular"
        type="cell-phone"
        className="col-span-2 lg:col-span-3"
      />
      <FormInput
        {...InputProps(`correo_ref${i}`)}
        placeholder="Correo"
        type="email"
        className="col-span-2 lg:col-span-3"
      />
    </Fragment>
  );
  if (
    watchTrabaja?.toString() === 'true' &&
    watchTipoDocumento?.toString() === '1'
  ) {
    seeActivity = true;
  }

  return (
    <>
      {seeActivity && (
        <section>
          <h4 className="font-bold">
            Actividad económica del Estudiante Estrella
          </h4>
          <div className="my-6 grid grid-cols-4 gap-6 gap-y-6 lg:grid-cols-12">
            <div className="col-span-4 grid grid-cols-4 gap-6 gap-y-6 lg:col-span-8 lg:grid-cols-8">
              <FormInput
                {...InputProps('ocupacion')}
                placeholder="Ocupación y fuente de ingresos principal"
                type="select"
                options={formConstants.OCUPACION}
                className="col-span-4 lg:col-span-5"
              />
              <FormInput
                {...InputProps('ingresos')}
                placeholder="(+) INGRESOS mensuales"
                type="currency"
                className="col-span-4 lg:col-span-3"
              />
            </div>
            <FormInput
              {...InputProps('egresos')}
              placeholder="(-) GASTOS mensuales"
              type="currency"
              className="col-span-4 h-full lg:col-span-3"
            />
          </div>
        </section>
      )}

      <section>
        <h4 className="font-bold">Referencias personales y laborales</h4>
        <div className="my-4 grid grid-cols-4 gap-4 gap-y-6 lg:my-6 lg:grid-cols-12 lg:gap-6">
          {[...Array(numRef)].map((e, i) => renderReferencia(i))}
        </div>
      </section>
    </>
  );
};

export default ActEcoEstudiante;
